export enum Section {
  Signature = "Signature",
  Hero = "Hero",
  AboutUs = "About Us",
  Gallery = "Gallery",
  Venue = "Venue",
  Hotel = "Hotel",
  Registry = "Registry",
  Faq = "Faq",
  Stream = "Stream",
  Rsvp = "Rsvp",
}
